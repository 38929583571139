







































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { cashManagementServices } from "@/services/cashmanagement.service";
import { settingsServices } from "@/services/settings.service";
import { masterServices } from "@/services/master.service";
import { Mode } from "@/models/enums/global.enum";
import MNotificationVue from "@/mixins/MNotification.vue";

import {
  RequestSettlement,
  SettlementBankLine,
  TransactionType,
} from "@/models/interface/cashManagement.interface";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { debounce } from "@/helpers/debounce";

interface DataListItems {
  transactionType: string;
  transactionDate: string;
  id?: string;
  key: number;
  referenceNumber: string;
  description: string;
  amount: number | string;
  bankDate: string;
  settled: boolean;
  disableByRow?: string[];
  disableCheckboxSide?: boolean;
}

interface OptionModel {
  id?: string;
  name?: string;
  key?: string;
  value?: string;
}
@Component({
  mixins: [MNotificationVue],
})
export default class CreateSettlement extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form!: WrappedFormUtils;
  formTotal!: WrappedFormUtils;
  formFind!: WrappedFormUtils;
  page = 0;
  limit = 10;
  dataListItems = [] as DataListItems[];
  selectedRowKeys = [] as number[];
  mode = "";
  dataCurrency = [] as DataMasterCurrency[];
  dataBranch = [] as OptionModel[];
  calculateTotal = 0;
  journalNumber = {
    id: "",
    name: "",
  };
  loading = {
    branch: false,
    submit: false,
    table: false,
    currency: false,
    bankName: false,
    save: false,
  };
  dataTransactionType = [] as OptionModel;

  show = {
    submit: true,
  };
  disabled = {
    branch: false,
    currency: false,
    rate: false,
    bankName: false,
    transactionType: false,
    transactionDateFrom: false,
    transactionDateTo: false,
    amountToSettled: false,
    settlementDate: false,
    delete: false,
    find: false,
  };
  dataBankName = [] as OptionModel[];
  formRules = {
    branch: {
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    settlementNo: {
      decorator: ["settlementNo"],
    },
    transactionDateFrom: {
      decorator: [
        "transactionDateFrom",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    transactionDateTo: {
      decorator: [
        "transactionDateTo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      decorator: [
        "rate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankName: {
      decorator: [
        "bankName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    transactionType: {
      decorator: ["transactionType"],
    },
    amountToSettle: {
      decorator: [
        "amountToSettle",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    settlementDate: {
      decorator: [
        "settlementDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },

    currencyBeginning: {
      decorator: ["currencyBeginning"],
    },
    totalBeginning: {
      decorator: ["totalBeginning"],
    },
    currencySettle: {
      decorator: ["currencySettle"],
    },
    totalSettle: {
      decorator: ["totalSettle"],
    },
    currencyEnding: {
      decorator: ["currencyEnding"],
    },
    totalEnding: {
      decorator: ["totalEnding"],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      width: 200,
      scopedSlots: { customRender: "isNull" },
      responsiveColCheckBox: [{ name: "settled" }],
      responsiveColDateMonth: [
        {
          name: "bankDate",
          placeholder: this.$t("lbl_bank_date_placeholder"),
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
    {
      title: this.$t("lbl_reference_number"),
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 200,
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_bank_date"),
      dataIndex: "bankDate",
      key: "bankDate",
      width: 200,
      scopedSlots: { customRender: "bankDate" },
    },
    {
      title: this.$t("lbl_settled"),
      dataIndex: "settled",
      key: "settled",
      width: 200,
      scopedSlots: { customRender: "settled" },
    },
  ] as ColumnTableCustom[];

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "createSettlement" });
    this.formTotal = this.$form.createForm(this, {
      name: "createSettlementTotal",
    });
    this.formFind = this.$form.createForm(this, { name: "findSettlement" });
  }
  getCheckboxProps(record) {
    return {
      props: {
        disabled: record.disableCheckboxSide,
      },
    };
  }
  created() {
    this.mode = this.$route.meta.mode;
  }
  getDetail(): void {
    cashManagementServices.detailSettlement(this.$route.params.id).then(res => {
      this.show.submit = false;
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }
      const data = {
        branch: res.branchName,
        settlementNo: res.settlementNumber,
        currency: res.currencyName,
        bankName: res.companyBankName,
        transactionType: res.transactionTypes
          .map(Object.values)
          .toString()
          .split(","),
        transactionDateFrom: res.transactionDateFrom,
        transactionDateTo: res.transactionDateTo,
      };
      this.setDataForm(data);
      const dataFind = {
        amountToSettle: currencyFormat(res.amountToSettle),
        settlementDate: res.settlementDate,
      };
      this.setDataFormFind(dataFind);
      const dataTotal = {
        currencyBeginning: res.currencyName,
        currencySettle: res.currencyName,
        currencyEnding: res.currencyName,
        totalBeginning: currencyFormat(res.beginingBalance),
        totalSettle: currencyFormat(res.totalAmountSettled),
        totalEnding: currencyFormat(res.endingBalance),
      };
      this.setFormTotal(dataTotal);
      res.settlementBankLines.forEach((item, index) => {
        const data = {
          transactionType: item.transactionType,
          transactionDate: moment(item.transactionDate).format(
            DEFAULT_DATE_FORMAT
          ),
          id: item.id,
          key: index,
          referenceNumber: item.referenceNumber,
          description: item.description,
          amount: currencyFormat(item.amount),
          bankDate: moment(item.bankDate).format(DEFAULT_DATE_FORMAT),
          settled: item.isSettled,
          disableByRow: ["bankDate", "settled"],
          disableCheckboxSide: true,
        };
        this.dataListItems.push(data);
      });
    });
  }
  handleCheckBoxTable(
    checked: boolean,
    key: number,
    nameColCheckbox: string,
    value: string
  ): void {
    this.dataListItems[key].settled = checked;
    debounce(() => {
      let countSettled = 0;
      this.dataListItems.forEach(element => {
        if (element.settled) {
          countSettled += element.amount
            ? changeCurrencytoNumeric(element.amount)
            : 0;
        }
      });

      this.formTotal.setFieldsValue({
        totalSettle: countSettled ? currencyFormat(countSettled) : "",
        totalEnding: currencyFormat(
          changeCurrencytoNumeric(
            this.formTotal.getFieldValue("totalBeginning")
          ) + countSettled
        ),
      });
    }, 200);
  }
  blurForm(value, form: string): void {
    if (value.target.value && !this.formFind.getFieldError(`${form}`)) {
      let dataobj = {};
      dataobj[form] = currencyFormat(value.target.value);
      this.formFind.setFieldsValue(dataobj);
    }
  }
  checkDropdown(value): void {
    if (this.dataBranch.findIndex(item => item.id === value.branchId) == -1) {
      this.dataBranch.push({
        id: value.branchId,
        name: value.branchName,
      });
    }
    if (
      this.dataCurrency.findIndex(
        item => item.currencyCode === value.currencyCode
      ) == -1
    ) {
      this.dataCurrency.push({
        id: value.currencyId,
        currencyCode: value.currencyCode,
        description: "",
      });
    }
    if (this.dataBankName.findIndex(item => item.id === value.bankId) == -1) {
      this.dataBankName.push({
        id: value.bankId,
        name: value.bankName,
      });
    }
  }
  searchData(value: string, type: string): void {
    switch (type) {
      case "branch":
        this.getListBranch(value);
        break;
      case "currency":
        this.getListCurrency(value, false);
        break;
      case "bankName":
        this.getListBankName(this.form.getFieldValue("currency"), true, value);
        break;
      default:
        break;
    }
  }

  mounted() {
    this.getListBranch("");
    this.getListCurrency("", true);
    this.getTransactionType();
    if (this.mode === Mode.EDIT) this.getDetail();
  }
  findData(): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        let params = {} as RequestQueryParamsModel;
        if (value.branch) params.branchId = value.branch;
        if (value.currency) params.currencyId = value.currency;
        if (value.bankName) params.bankId = value.bankName;
        if (value.transactionType.length > 0)
          params.transactionType = value.transactionType.toString();
        if (value.transactionDateFrom)
          params.dateFrom = moment(value.transactionDateFrom)
            .set({ hour: 0, minute: 0, second: 0 })
            .format()
            .split("+")[0]
            .replaceAll("T", " ");
        if (value.transactionDateTo)
          params.dateTo = moment(value.transactionDateTo)
            .set({ hour: 23, minute: 59, second: 59 })
            .format()
            .split("+")[0]
            .replaceAll("T", " ");
        cashManagementServices.listBankMovement(params).then(res => {
          const dataSetFrom = {
            totalBeginning: res.beginingBalance
              ? currencyFormat(res.beginingBalance)
              : "",
            totalSettle: "",
            totalEnding: "",
          };
          this.setFormTotal(dataSetFrom);
          this.setFromTable(res);
        });
      }
    });
  }
  setFromTable(values): void {
    let obj = {} as DataListItems;
    this.dataListItems = [];
    values.movementList.forEach((element, index) => {
      obj = {
        id: element.id,
        key: index,
        transactionType: element.transactiontype,
        referenceNumber: element.referencenumber,
        description: element.description,
        transactionDate: element.transactiondate,
        amount: element.amount,
        bankDate: "",
        settled: false,
        disableCheckboxSide: false,
      };

      this.dataListItems.push(obj);
    });
  }
  setFormTotal(values): void {
    for (const key in values) {
      this.formTotal.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formTotal.setFieldsValue(values);
  }
  setDataForm(values): void {
    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }
  setDataFormFind(values): void {
    for (const key in values) {
      this.formFind.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formFind.setFieldsValue(values);
  }
  handleDateMonth(
    value: string,
    key: number,
    objectColDate: string,
    dateString: string
  ): void {
    this.dataListItems[key][objectColDate] = moment(value).format();
    this.dataListItems = this.dataListItems.slice();
  }
  getListOfPreference(query: string): void {
    let params = {
      limit: 10,
      page: 0,
      name: query,
    } as RequestQueryParamsModel;
    settingsServices.getPreference(params).then(res => {
      const data = {
        currency: res[0].value,
      };
      this.setDataForm(data);
      this.changeData(res[0].value, "currency");
    });
  }
  getTransactionType(): void {
    cashManagementServices.transactionType().then(res => {
      this.dataTransactionType = res;
    });
  }
  getListBankName(value: string, search: boolean, searchValue: string): void {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `createdDate:desc`,
      search: `currency.currencyCode~*${
        this.dataCurrency.find(item => item.id === value)?.currencyCode
      }*`,
    };
    this.loading.bankName = true;
    if (search && searchValue) {
      params.search = `bankAccName~*${searchValue}*_OR_bankAccNumber~*${searchValue}*_OR_bankName~*${searchValue}*_AND_currency.currencyCode~*${
        this.dataCurrency.find(item => item.id === value)?.currencyCode
      }*`;
    }
    masterServices
      .listCompanyBank(params)
      .then(res => {
        res.data.forEach(item => {
          item["name"] = item.bankName + " - " + item.bankAccNumber;
        });
        this.dataBankName = res.data;
      })
      .finally(() => {
        this.loading.bankName = false;
      });
  }
  getListCurrency(value: string, init: boolean): void {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value && !init)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => {
        this.loading.currency = false;
        if (init && this.mode === Mode.CREATE)
          this.getListOfPreference(`search=key~*feature_base_currency*`);
      });
  }

  changeData(value: string, type: string): void {
    if (type === "currency") {
      this.getListBankName(value, false, "");
      const data = {
        bankName: "",
      };
      this.setDataForm(data);
      const dataTotal = {
        currencyBeginning: this.dataCurrency.find(item => item.id === value)
          ?.currencyCode,
        currencySettle: this.dataCurrency.find(item => item.id === value)
          ?.currencyCode,
        currencyEnding: this.dataCurrency.find(item => item.id === value)
          ?.currencyCode,
      };
      this.setFormTotal(dataTotal);
    }
  }

  generateRate(value: string) {
    let params = {
      name: `search=key~*feature_base_currency*`,
    } as RequestQueryParamsModel;
    settingsServices.getPreference(params).then(data => {
      params = {
        limit: 1,
        page: 0,
        search: `search=fromCurrency.currencyCode~${value}_AND_toCurrency.currencyCode~${data[0].value}`,
      } as RequestQueryParamsModel;
      settingsServices.listOfCurrency(params, "").then(res => {
        const data = {
          rate: res.data.length ? currencyFormat(res.data[0].rate) : "",
        };
        this.setDataForm(data);
      });
    });
  }
  onClickButton(type: "back" | "submit") {
    if (type === "submit") {
      this.form.validateFields((err, value) => {
        if (!err)
          this.formFind.validateFields((errFind, valueFind) => {
            if (!errFind)
              this.formTotal.validateFields((errTotal, valueTotal) => {
                if (!errTotal) {
                  let dataPost = {} as RequestSettlement;
                  let dataTransaction = [] as TransactionType[];
                  let dataSettlement = [] as SettlementBankLine[];
                  dataPost.branchId = value.branch;
                  dataPost.currencyId = value.currency;
                  dataPost.companyBankId = value.bankName;
                  dataPost.transactionDateFrom = moment(
                    value.transactionDateFr
                  ).format();
                  dataPost.transactionDateTo = moment(
                    value.transactionDateTo
                  ).format();
                  dataPost.amountToSettle = changeCurrencytoNumeric(
                    valueFind.amountToSettle
                  );
                  dataPost.settlementDate = moment(
                    valueFind.settlementDate
                  ).format();
                  dataPost.beginingBalance = changeCurrencytoNumeric(
                    valueTotal.totalBeginning
                  );
                  dataPost.totalAmountSettled = changeCurrencytoNumeric(
                    valueTotal.totalSettle
                  );
                  dataPost.endingBalance = changeCurrencytoNumeric(
                    valueTotal.totalEnding
                  );
                  if (value.transactionType.length)
                    value.transactionType.forEach(element => {
                      dataTransaction.push({
                        settlementTransactionType: element,
                      });
                    });
                  dataPost.transactionTypes = dataTransaction;
                  if (this.dataListItems.length)
                    this.dataListItems.forEach(element => {
                      if (element.settled)
                        dataSettlement.push({
                          cashBankMovementId: element.id as string,
                          transactionType: element.transactionType,
                          referenceNumber: element.referenceNumber,
                          description: element.description,
                          transactionDate: element.transactionDate
                            ? moment(element.transactionDate).format()
                            : "",
                          amount: element.amount
                            ? changeCurrencytoNumeric(element.amount)
                            : 0,
                          bankDate: element.bankDate
                            ? moment(element.bankDate).format()
                            : "",
                          isSettled: element.settled,
                        });
                    });
                  dataPost.settlementBankLines = dataSettlement;
                  this.fetchCreateSettlement(dataPost);
                }
              });
          });
      });
    } else {
      this.$confirm({
        title: this.$t("lbl_leave_page"),
        onOk: () => {
          this.$router.push("/cash-management/transactions/settlement");
        },
        onCancel() {
          return;
        },
      });
    }
  }
  fetchCreateSettlement(dataPost: RequestSettlement): void {
    this.loading.submit = true;
    cashManagementServices
      .createSettlement(dataPost)
      .then(res => {
        this.$router.push("/cash-management/transactions/settlement");
        this.showNotifSuccess("lbl_notif_settlement_number", {
          settlementNo: res.documentNo,
        });
      })
      .finally(() => (this.loading.submit = false));
  }
  getListBranch(value: string): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }

  onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys;
  }
  showConfirmation() {
    if (this.selectedRowKeys.length)
      this.$confirm({
        title: this.$t("lbl_modal_delete_title_confirm"),
        content: this.$t("lbl_modal_delete_info", {
          count: this.selectedRowKeys.length,
        }),
        onOk: () => {
          this.dataListItems = this.dataListItems.filter(data => {
            return !this.selectedRowKeys.includes(data.key);
          });
          this.dataListItems.forEach((data, index) => (data.key = index));
          this.dataListItems = this.dataListItems.slice();
          this.selectedRowKeys = [];
        },
        onCancel() {
          return;
        },
      });
    else {
      this.showNotifError("lbl_modal_delete_error_description");
    }
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
